import { $fileAxios, cacheableAxios } from "@/utils/axios";
import { dispatchEvent } from "./Helper";
import isEqual from "lodash/isEqual";

async function setImageSrc(el: HTMLElement, binding: any, vNode: any) {
  if (binding.oldValue === undefined || !isEqual(binding.value, binding.oldValue)) {
    let imageURL = binding.value?.url;
    if (!imageURL) {
      throw new Error("[vue-image] please set url.");
    }

    if (binding.value?.noFetch) {
      return;
    }

    dispatchEvent(el, binding, vNode, "loading");

    if (imageURL instanceof Promise) {
      try {
        const response = await imageURL;

        if (response?.status == "success") {
          el.setAttribute("src", response.src);

          dispatchEvent(el, binding, vNode, "success");
          return;
        } else {
          imageURL = response?.src;
        }
      } catch (e) {
        dispatchEvent(el, binding, vNode, "fail");
        return;
      }
    }

    let httpAxios: any = $fileAxios;

    if (binding.value?.httpCache) {
      httpAxios = await cacheableAxios();
    }

    if (!httpAxios) {
      throw new Error("[vue-image] cannot locate Axios");
    }

    httpAxios({
      method: "get",
      url: imageURL,
      responseType: "arraybuffer",
    })
      .then(function (response: any) {
        el.setAttribute("valid", "true");
        const mimeType = response.headers["content-type"].toLowerCase();

        // @ts-ignore
        const imageBase64 = new Buffer(response.data, "binary").toString("base64");

        const imageSrc = "data:" + mimeType + ";base64," + imageBase64;

        el.setAttribute("src", imageSrc);

        dispatchEvent(el, binding, vNode, "success");
      })
      .catch(function (err: any) {
        el.setAttribute("valid", "false");
        el.setAttribute("src", "");
        dispatchEvent(el, binding, vNode, "fail");
        console.log(err);
      });
  }
}

export default class Image {
  public beforeMount(el: HTMLElement, binding: any, vNode: any) {
    setImageSrc(el, binding, vNode);
  }

  public updated(el: HTMLElement, binding: any, vNode: any) {
    setImageSrc(el, binding, vNode);
  }
}
